<template>
    <div style="max-width: 700px; border-left: 1px; border-right: 1px;" class="mx-auto">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'Setup-Block',
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>